import { 
    SET_TYPE_MINIMIZE, 
    REMOVE_TYPE, 
    SET_INDEX_CURRENT, 
    MINIMIZE_NEW_MAIL, 
    MINIMIZE_NEW_SYSTEM, 
    BUILD_SHOW_FLAG, 
    MINIMIZE_DASHBOARD, 
    MAXIMIZE_MAIL 
} from "../actions";

export const minimizePopups = (state = {
    minimize: [],
    indexCurrent: null,
    minimizeNewSystem: [],
    minimizeNewMail: [],
    minimizeDashboard: [],
    buildShow: false,
}, action) => {
    switch (action.type) {
        case SET_TYPE_MINIMIZE: {
            let temp = [...state.minimize];
            let index = state.indexCurrent;

            const existingItem = temp.find((e) => e.name === action.payload.name);
            if (existingItem) {
                temp = temp.map((item) =>
                    item.name === action.payload.name
                        ? { ...item, show: action.payload.show }
                        : item
                );
                if (action.payload.show) {
                    index = temp.find((item) => item.name === action.payload.name)?.num ?? index;
                } else {
                    index = temp.reverse().find((item) => item.show)?.num ?? null;
                }
            } else {
                temp.push({ ...action.payload });
            }

            return { ...state, minimize: temp, indexCurrent: index };
        }
        case REMOVE_TYPE: {
            const temp = state.minimize.filter((e) => e.name !== action.payload);
            const newIndex = temp.length > 0 ? temp[temp.length - 1].num : null;
            return { ...state, minimize: temp, indexCurrent: newIndex };
        }
        case SET_INDEX_CURRENT:
            return { ...state, indexCurrent: action.payload };
        case MINIMIZE_NEW_MAIL: {
            if (!action.id && !action.obj && !action.show) {
                return { ...state, minimizeNewMail: [] };
            }

            if (action.delete) {
                const updated = state.minimizeNewMail.filter((e) => e.id !== action.id);
                return { ...state, minimizeNewMail: updated };
            }

            if (action.obj) {
                const updated = [...state.minimizeNewMail, action.obj];
                return { ...state, minimizeNewMail: updated };
            }

            const updated = state.minimizeNewMail.map((e) =>
                e.id === action.id ? { ...e, show: action.show } : e
            );
            return { ...state, minimizeNewMail: updated };
        }
        case MINIMIZE_NEW_SYSTEM: {
            if (!action.id && !action.obj && !action.show) {
                return { ...state, minimizeNewSystem: [] };
            }

            if (action.delete) {
                const updated = state.minimizeNewSystem.filter((e) => e.id !== action.id);
                return { ...state, minimizeNewSystem: updated };
            }

            if (action.obj) {
                const updated = [...state.minimizeNewSystem, action.obj];
                return { ...state, minimizeNewSystem: updated };
            }

            const updated = state.minimizeNewSystem.map((e) =>
                e.id === action.id ? { ...e, show: action.show } : e
            );
            return { ...state, minimizeNewSystem: updated };
        }
        case MINIMIZE_DASHBOARD: {
            if (!action.id && !action.obj && !action.show) {
                return { ...state, minimizeDashboard: [] };
            }

            if (action.delete) {
                const updated = state.minimizeDashboard.filter((e) => e.id !== action.id);
                return { ...state, minimizeDashboard: updated };
            }

            if (action.obj && !action?.id) {
                const updated = [...state.minimizeDashboard, action.obj];
                return { ...state, minimizeDashboard: updated };
            }

            const updated = state.minimizeDashboard.map((e) =>
                e.id === action.id
                    ? { ...e, show: action.show, ...(action.obj && { message: action.obj }) }
                    : e
            );
            return { ...state, minimizeDashboard: updated };
        }
        case MAXIMIZE_MAIL: {
            const updated = state.minimizeNewMail.map((e) =>
                e.id === action.id ? { ...e, maximize: action.flag } : e
            );
            return { ...state, minimizeNewMail: updated };
        }
        case BUILD_SHOW_FLAG:
            return { ...state, buildShow: action.payload };
        default:
            return state;
    }
};

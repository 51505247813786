import { CHANGE_LIKE_POST, MARK_SOCIAL, SET_SOCIAL_MEDIA, SOCIAL_FLAG } from "../actions/index";

import { USER_GAME_NAME, USER_ID_IN_GAME } from "../../config.inc";

import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import { addOrReplaceItemInArr } from "../function/addOrReplaceItemInArr";

const findName = (id, players) => {
  const userId = sessionStorage.getItem(USER_ID_IN_GAME);
  if (userId === id) return sessionStorage.getItem(USER_GAME_NAME);
  if (players && players.length > 0) {
    let flag = false;
    players.forEach((e) => {
      if (e.id === id) {
        flag = e.name_for_game;
      }
    });
    if (flag) {
      return flag;
    } else {
      return "User";
    }
  } else {
    return "User";
  }
};
const createTime = () => {
  return new Date().toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
};
const checkType = (link, type) => {
  if (
    type === "img" &&
    (link.indexOf("png") > 0 ||
      link.indexOf("jpg") > 0 ||
      link.indexOf("jpeg") > 0 ||
      link.indexOf("webp") > 0)
  ) {
    return link;
  }
  if (
    type === "video" &&
    (link.indexOf("mp4") > 0 || link.indexOf("mpeg4") > 0)
  ) {
    return link;
  }
  return null;
};

const getComments = (arr, players) => {
  let comments = [];
  arr.forEach((e) => {
    let newComment = {
      id: e.id,
      user_id: e.user_id,
      name: findName(e.user_id, players),
      body: checkIfIsEncodeText(e.comment, "string"),
      profile_image: findImgUserProfile(e.user_id, players)
    };
    comments.push(newComment);
  });
  return comments;
};

const checkUnread = (id, arr) => {
  let unRead = [...arr.filter((e) => e.sender_by.id !== id && !e.is_read)];
  let unReadIndexed = [];
  unRead.forEach((post, i, posts) =>
    unReadIndexed.push({ ...post, index: posts.length - i - 1 })
  );
  let read = [...arr.filter((e) => e.sender_by.id === id || e.is_read)];
  read.forEach((e) => {
    if (e.sender_by.id === id) {
      e.is_read = true;
    }
  });
  unReadIndexed = unReadIndexed.reverse();
  read = read.reverse();
  read = [...unReadIndexed, ...read];
  return {
    count: unReadIndexed.length,
    unRead: unReadIndexed,
    arr: read,
  };
};

const findImgUserProfile = (id, players) => {
  let img = null;
  if (players?.length > 0) {
    players.forEach((player) => {
      if (player.id === id) {
        img = player.profile_image;
      }
    })
  }
  return img;
}



export const socialMediaReducer = (
  state = {
    socialMediaArr: [],
    unReadPosts: 0,
    unReadPostsArr: [],
    flag: false,
  },
  action
) => {
  switch (action.type) {
    case SET_SOCIAL_MEDIA: {
      if (action.payload.length > 0) {
        let socialPosts = [...state.socialMediaArr];
        let socialPostUnread = [...state.unReadPostsArr];
        action.payload.forEach((e) => {
          let post = {
            id: e.id,
            sender_img: findImgUserProfile(e.sender_id, action.players),
            sender_by: {
              name:
                e.sender_id === action.id
                  ? sessionStorage.getItem(USER_GAME_NAME)
                  : findName(e.sender_id, action.players),
              id: e.sender_id ? e.sender_id : "",
            },
            time: e.send_date ? e.send_date : createTime(),
            timeSend: e.last_update_date ? e.last_update_date : createTime(),
            text: e.post ? checkIfIsEncodeText(e.post, "string") : null,
            image: e.file_link ? checkType(e.file_link, "img") : null,
            video: e.file_link ? checkType(e.file_link, "video") : null,
            to_list: e.to_list ? e.to_list : null,
            likes: e.likes_count ? e.likes_count : "0",
            comments:
              e.comments.length > 0
                ? getComments(e.comments, action.players)
                : [],
            like_post: Array.isArray(e.like_post) ? e.like_post : [],
            is_read: action.is_admin ?
              e.is_admin_read && e.is_admin_read === "1" ? true : false : e.is_read && e.is_read === "1" ? true : false,
            status: e?.status === "1" ? true : false
          };
          if (socialPosts?.length > 0) {
            const { addFlag, arrCurrent } = addOrReplaceItemInArr(socialPosts, post, false, true);
            socialPosts = arrCurrent;
          } else {
            if (post?.status) {
              socialPosts.push(post);
            }
          }
        });
        let res = checkUnread(action.id, socialPosts);
        socialPostUnread = res.unRead;
        socialPosts = [...res.arr];
        socialPosts.sort((a, b) => {
          return new Date(b.timeSend).getTime() - new Date(a.timeSend).getTime();
        });
        return {
          ...state,
          socialMediaArr: socialPosts,
          unReadPosts: socialPostUnread?.length,
          unReadPostsArr: socialPostUnread,
        };
      } else {
        return {
          ...state,
          socialMediaArr: [],
          unReadPosts: 0,
          unReadPostsArr: [],
        };
      }
    }
    case CHANGE_LIKE_POST: {
      let index = state.socialMediaArr.findIndex((e) => e.id === action.payload);
      const post = state.socialMediaArr;
      if (index >= 0) {
        const likeIndex = post[index].like_post.findIndex(id => id === action.from);

        if (likeIndex >= 0) {
          // משתמש כבר לחץ לייק, מסירים אותו מהמערך
          post[index].like_post.splice(likeIndex, 1);
        } else {
          // משתמש עדיין לא לחץ לייק, מוסיפים אותו למערך
          post[index].like_post.push(action.from);
        }
      }
      return {
        ...state, socialMediaArr: [...post]
      }
    }
    case MARK_SOCIAL: {
      let index = state.socialMediaArr.findIndex((e) => e.id === action?.payload);
      if (index >= 0) {
        state.socialMediaArr[index].is_read = true;
        state.unReadPostsArr = state.unReadPostsArr.filter((e) => e.id !== state.socialMediaArr[index]?.id);
      }
      return {
        ...state,
        socialMediaArr: state.socialMediaArr,
        unReadPosts: state.unReadPostsArr?.length,
        unReadPostsArr: state.unReadPostsArr,
      };
    }
    case SOCIAL_FLAG: {
      return { ...state, flag: action.flag };
    }
    default: {
      return state;
    }
  }
};

import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { StyleSheet, Pressable } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { useSelector } from "react-redux";

const SpeechToTextButton = React.forwardRef(({ onTranscription, color, size }, ref) => {
    const [listening, setListening] = useState(false);
    const [loading, setLoading] = useState(false);

    const lang = useSelector((state) => state.setting.setting.lang);

    const languageMapping = {
        de: "de-DE", // גרמנית
        en: "en-US", // אנגלית
        es: "es-ES", // ספרדית
        fr: "fr-FR", // צרפתית
        he: "he-IL", // עברית
        it: "it-IT", // איטלקית
        th: "th-TH", // תאילנדית
    };

    const recognizer = useRef(null);
    const interimResult = useRef(""); // לתוצאות ביניים
    const finalResult = useRef(""); // לתוצאה הסופית

    const silenceTimeout = useRef(null);
    const SILENCE_DURATION = 4000; // זמן שקט במילישניות לפני עצירה

    const handlePress = () => {
        if (listening) {
            stopRecognition();
            return;
        }
        startRecognition();
    };

    const startRecognition = () => {
        if (recognizer.current) {
            console.warn("Recognizer is already running.");
            return;
        }
        setLoading(true);

        // איפוס התוצאות בתחילת הקלטה חדשה
        interimResult.current = "";
        finalResult.current = "";

        const speechConfig = sdk.SpeechConfig.fromSubscription(
            "EPi3CxmA0z7AHQlbYvmnkd1Em2sXuhZ0surhFZeTxgQaXx65JR3oJQQJ99ALACi5YpzXJ3w3AAAYACOG9b9R",
            "northeurope"
        );
        speechConfig.speechRecognitionLanguage = languageMapping[lang]; // שפה מתאימה

        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        recognizer.current = new sdk.SpeechRecognizer(speechConfig, audioConfig);

        const handleSilence = () => {
            stopRecognition();
        };

        recognizer.current.recognizing = (sender, event) => {
            if (silenceTimeout.current) {
                clearTimeout(silenceTimeout.current);
            }
            // תוצאות ביניים - נשמור בנפרד
            interimResult.current = event.result.text;
            // שולחים את השילוב של התוצאה הסופית + תוצאות הביניים
            onTranscription(finalResult.current + " " + interimResult.current);
        };

        recognizer.current.recognized = (sender, event) => {
            // כשמזוהה משפט שלם - מוסיפים אותו לתוצאה הסופית
            if (event.result.text) {
                finalResult.current += (finalResult.current ? " " : "") + event.result.text;
                interimResult.current = ""; // מאפסים את תוצאות הביניים
                onTranscription(finalResult.current);

                // מפעילים טיימר שקט חדש
                silenceTimeout.current = setTimeout(handleSilence, SILENCE_DURATION);
            }
        };

        recognizer.current.canceled = (sender, event) => {
            console.error(`Canceled: ${event.errorDetails}`);
            setLoading(false);
            setListening(false);
        };

        recognizer.current.startContinuousRecognitionAsync(() => {
            setListening(true);
            setLoading(false);
            silenceTimeout.current = setTimeout(handleSilence, SILENCE_DURATION);
        });
    };

    const stopRecognition = () => {
        if (recognizer.current) {
            recognizer.current.stopContinuousRecognitionAsync(
                () => {
                    setListening(false);
                    recognizer.current = null; // איפוס המופע
                },
                (err) => {
                    console.error(`Error stopping recognition: ${err}`);
                }
            );
        }
    };

    useImperativeHandle(ref, () => ({
        stopRecognition,
    }));

    return (
        <Pressable onPress={handlePress} style={styles.container}>
            <FontAwesome name="microphone" size={size || "1.6vw"} color={listening ? "#FF0000" : color} />
        </Pressable>
    );
});

const styles = StyleSheet.create({
    container: {
        width: "2vw",
        alignItems: "center",
        justifyContent: "center"
    },
    button: {
        alignItems: "center",
        justifyContent: "center",
    },
});

export default SpeechToTextButton;

import { StyleSheet, Text, View, Modal, ScrollView, Pressable, Image, Dimensions } from 'react-native';
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LeftNav from './LeftNav';
import InfoContainer from "./info/InfoContainer";
import BuildContainer from "./build/BuildContainer";
import UserInfo from './left-info/UserInfo';
import PlayersList from './left-info/PlayersList';
import AddPlayer from "./info/AddPlayer";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { changeFlowZoom, openRealPlayer, openRepPlayer, setFlowTimeColumns } from '../../../redux/actions/creatorActions';
import UseResponsiveScreen from '../../modules/UseResponsiveScreen';
import Flow from './build/Flow';
import ScrollHorizontal from './build/ScrollHorizontal';
import AnalyticsIndex from "./Analytics/AnalyticsIndex";
import { Entypo } from '@expo/vector-icons';
import AnalyticsFilter from './Analytics/AnalyticsFilter';
import AnimationLoading from '../../modules/AnimationLoading';
import { GameInfoContext } from '../../../ContextData/GameInfo';
import { buildShowFlag, loading } from '../../../redux/actions';
import { useCallback } from 'react';
import FlowTable from "./build/tableFlow/FlowTable";
import DashboardIndex from './Dashboard/DashboardIndex';
import { useRawDataContext } from '../../../ContextData/RawData';
import RawData from './rawData/RawData';
import Filter from './rawData/Filter';

export default function SimulationBuildIndex({ flag }) {

    const { widthS, heightS } = UseResponsiveScreen();
    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const styles = stylesR(background, color);

    const containerRef = useRef(null);
    const listRef = useRef(null);
    const scrollRef = useRef(null);
    const buildRef = useRef(null);
    const flowRef = useRef(null);
    const flowTable = useRef(null);
    const listPlayersRef = useRef(null);
    const scrollPlayersRef = useRef(null);
    const topPlayers = useRef(null);

    const { editGame } = useContext(GameInfoContext);

    const dispatch = useDispatch();

    const images = useSelector((state) => state.style.images);
    const flowTimeColumns = useSelector(state => state.swimlane.flowTimeColumns);
    const animationFlag = useSelector((state) => state.loadingAnimation.flag);
    const minimizeFlag = useSelector((state) => state.minimize.buildShow);

    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
    const gameInfoPermission = useSelector(state => state.gameInfoArr);

    const infoFlag = useSelector(state => state.build.infoFlag);
    const buildFlag = useSelector(state => state.build.buildFlag);
    const analyticsFlag = useSelector(state => state.analytics.flags.flag);
    const actionsArrState = useSelector((state) => state.swimlane.actions);
    const dashboardFlag = useSelector(state => state.build.dashboardFlag);
    const { rawDataFlag, setRawDataFlag } = useRawDataContext();

    const realPFlag = useSelector(state => state.players.flagReal);
    const repPFlag = useSelector(state => state.players.flagRep);

    const zoomSize = useSelector(state => state.swimlane.zoom);

    const playerPermission = useMemo(() => Number(gameInfoPermission?.permission) < 10, [gameInfoPermission]);

    // const scroll = useSelector(state => state.swimlane.scroll);

    const [newScroll, setNewScroll] = useState(0);
    const [err, setErr] = useState(false);

    const [open, setOpen] = useState(false);
    const [move, setMove] = useState();
    const [indexPlayer, setIndexPlayer] = useState();
    const [indexAction, setIndexAction] = useState(0);
    const [round, setRound] = useState(zoomSize === 50 ? 15 : 10);
    const [load, setLoad] = useState(false);
    const [zIndex, setZindex] = useState(10);
    const [showTable, setShowTable] = useState(false);
    const [actionsState, setActionsState] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const { handleAddRow, component, tableRef, saveNewRow, removeNewRow } = FlowTable({
        component: true,
        flag: showTable,
        setFlag: setShowTable,
        actionsArrState: actionsState,
        setActionsState: setActionsState,
        permission: playerPermission
    });

    const [infoObjectToCompare, setInfoObjectToCompare] = useState({
        simulationName: null,
        date: null,
        game_goals: "",
        time: null,
        timeUnits: null,
        lang: null,
        story: null,
        is_demo: gameInfo?.is_demo,
        files: null,
        game_colors: null,
        game_logo: null,
        video: null,
        client_id: null,
        game_time_zone: null,
    });
    const [infoObjectToDisplay, setInfoObjectToDisplay] = useState({
        simulationName: null,
        date: null,
        game_goals: "",
        time: null,
        timeUnits: null,
        lang: null,
        story: null,
        is_demo: gameInfo?.is_demo,
        files: null,
        game_colors: null,
        game_logo: null,
        video: null,
        client_id: null,
        game_time_zone: null,
    });

    const editGameInfo = (objCurrent, objCompare) => {
        let keys = createKeysInfo(objCurrent, objCompare);
        if (keys.length > 0) {
            dispatch(loading(true));
            editGame(infoObjectToDisplay, keys)
                .then(() => {
                    dispatch(loading(false));
                })
                .catch(() => {
                    dispatch(loading(false));
                });
        } else {
            setTimeout(() => {
                dispatch(loading(false));
            }, 500);
        }
    }

    const createKeysInfo = (objCurrent, objCompare) => {
        let types = ["is_demo", "story", "game_goals", "lang", "simulationName", "game_objectives", "date", "time", "game_colors", "game_logo", "client_id", "game_time_zone"];
        let keys = [];
        Object.keys(objCurrent).forEach((e) => {
            if (objCompare[e] !== objCurrent[e] && types.findIndex((k) => k === e) >= 0) {
                keys.push(e);
            }
        });
        return keys;
    }

    const changeZoom = (type) => {
        dispatch(loading(true));
        setTimeout(() => {
            dispatch(loading(false));
        }, 1000);
        switch (type) {
            case "-":
                if (Number(zoomSize) === 75) {
                    return dispatch(changeFlowZoom("50"));
                } else if (Number(zoomSize) === 100) {
                    return dispatch(changeFlowZoom("75"));
                }
                else {
                    return;
                }
            case "+":
                if (Number(zoomSize) === 50) {
                    return dispatch(changeFlowZoom("75"));
                } else if (Number(zoomSize) === 75) {
                    return dispatch(changeFlowZoom("100"));
                } else {
                    return;
                }
        }
    }

    useEffect(() => {
        if (+zoomSize === 50 && round < 20) {
            setRound(20);
        }
    }, [zoomSize, round]);

    useEffect(() => {
        setActionsState([...actionsArrState]);
    }, [actionsArrState]);

    useEffect(() => {
        if (!isEdit) {
            setInfoObjectToDisplay({
                ...infoObjectToDisplay,
                id: gameInfo.id,
                simulationName: gameInfo.game_name,
                date: new Date(gameInfo.start_time ? gameInfo.start_time : gameInfo.scheduale_start_time).toDateString(),
                time: new Date(gameInfo.start_time ? gameInfo.start_time : gameInfo.scheduale_start_time).toLocaleTimeString().padStart(11, '0'),
                lang: gameInfo.game_lang ? gameInfo.game_lang : "en",
                game_objectives: gameInfo.game_objectives,
                game_goals: gameInfo.game_goals,
                story: gameInfo.background_story,
                video: gameInfo.countdown_video,
                start: gameInfo.start_time ? true : false,
                files: gameInfo.files,
                game_colors: gameInfo.game_colors,
                game_logo: gameInfo.game_logo,
                is_demo: gameInfo.is_demo,
                client_id: gameInfo?.client_id,
                game_time_zone: gameInfo?.game_time_zone
            });
            setInfoObjectToCompare({
                ...infoObjectToCompare,
                id: gameInfo.id,
                simulationName: gameInfo.game_name,
                date: new Date(gameInfo.start_time ? gameInfo.start_time : gameInfo.scheduale_start_time).toDateString(),
                time: new Date(gameInfo.start_time ? gameInfo.start_time : gameInfo.scheduale_start_time).toLocaleTimeString().padStart(11, '0'),
                lang: gameInfo.game_lang ? gameInfo.game_lang : "en",
                game_objectives: gameInfo.game_objectives,
                game_goals: gameInfo.game_goals,
                story: gameInfo.background_story,
                video: gameInfo.countdown_video,
                start: gameInfo.start_time ? true : false,
                files: gameInfo.files,
                game_colors: gameInfo.game_colors,
                game_logo: gameInfo.game_logo,
                is_demo: gameInfo.is_demo,
                client_id: gameInfo?.client_id,
                game_time_zone: gameInfo?.game_time_zone
            });
        }
    }, [gameInfo, isEdit]);

    useEffect(() => {
        if (listRef.current) {
            document.getElementById("none-scroll-players")?.addEventListener(("scroll"), ({ target }) => {
                if ((target.scrollTop + target.offsetHeight) >= target.scrollHeight && listRef.current) {
                    listRef.current?.scrollTo({ x: listRef.current.scrollRight, y: listRef.current.clientHeight, animated: false })
                }
            })
        }
    }, [listRef.current, document.getElementById("none-scroll-players")]);

    // useMemo(() => {
    //     if (scrollRef.current) {
    //         scrollRef.current.addEventListener(('scroll'), ({ target }) => {
    //             if ((target.scrollLeft + target.offsetWidth) >= target.scrollWidth - 50) {

    //             }
    //         })
    //     }
    // }, [scrollRef.current, actionsArrState, flowTimeColumns]);

    const handleScroll = useCallback((event) => {
        const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
        const isScrolledToEnd = layoutMeasurement.width + contentOffset.x >= (contentSize.width - 8);
        if (isScrolledToEnd && +actionsArrState[actionsArrState.length - 1]?.time > +flowTimeColumns[flowTimeColumns.length - 1]) {
            dispatch(loading(true));
            onEndReached();
        }
    }, [flowTimeColumns]);

    const onEndReached = () => {
        setRound(round + 10);
        setTimeout(() => {
            dispatch(loading(false));
        }, 1000);
    };


    useEffect(() => {
        if (containerRef.current) {
            window.addEventListener("mousedown", (e) => {
                if (containerRef.current?.contains(e.target)) {
                    if (!minimizeFlag) {
                        dispatch(buildShowFlag(true));
                    }
                }
                //  else if (minimizeFlag) {
                //     dispatch(buildShowFlag(false));
                // }
            })
        }
    }, [containerRef.current, zIndex]);

    useEffect(() => {
        if (minimizeFlag) {
            setZindex(10);
        } else {
            setZindex(1);
        }
    }, [minimizeFlag]);

    /// component

    const loadingComponent = (
        (load || animationFlag) && <View nativeID='popup-build'
            style={{ position: "absolute", width: "100%", height: Dimensions.get("window").height, backgroundColor: "#01010161", zIndex: 10000, alignItems: "center", justifyContent: "center" }}
        >
            <AnimationLoading />
        </View>
    )

    const infoBody = (
        <>
            <View style={[styles.leftInfo, { borderLeftColor: color.infoBorder, backgroundColor: background.leftInfo }]}>
                <View style={{ flex: 2, borderBottomColor: color.infoBorder, borderBottomWidth: 1 }}>
                    <UserInfo />
                </View>
                <View style={{ flex: 4 }}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        style={{ maxHeight: "100%" }}
                        contentContainerStyle={{ minHeight: "101%" }}
                        ref={scrollRef}
                    >
                        <PlayersList permission={playerPermission} buildFlag={false} topPlayers={topPlayers} listRef={listRef} />
                    </ScrollView>
                </View>
            </View>
            <View style={styles.body}>
                <InfoContainer
                    editGameInfo={() => {
                        editGameInfo(infoObjectToDisplay, infoObjectToCompare);
                    }}
                    permission={playerPermission}
                    setLoad={setLoad}
                    infoObj={infoObjectToDisplay}
                    setInfoObj={(obj) => setInfoObjectToDisplay(obj)}
                    err={err}
                    setErr={setErr}
                    setIsEdit={setIsEdit}
                />
            </View>
        </>
    )

    const buildBody = () => {
        return (
            <View style={{ flexDirection: "row", flex: 9.5, }}>
                <ScrollSync
                    ref={scrollPlayersRef}
                    showsVerticalScrollIndicator={false}
                >
                    <>
                        <View style={styles.buildLeft} ref={buildRef}>
                            <View style={{ width: widthS * 140, flex: 1.8 }}>
                                <UserInfo />
                            </View>
                            <ScrollSyncPane group="a">
                                <View nativeID='none-scroll-players' style={{ overflow: "auto", flex: 3, maxHeight: "100%", width: widthS * 140, height: "100%", borderTopColor: color.border, borderTopWidth: 1, backgroundColor: background.infoBody, }} >
                                    <View style={{ minHeight: "100%", }} ref={listPlayersRef}>
                                        <PlayersList buildFlag={showTable ? false : true} topPlayers={topPlayers} indexPlayer={indexPlayer} setIndexPlayer={setIndexPlayer} />
                                    </View>
                                </View>
                            </ScrollSyncPane>
                        </View>
                        <View style={styles.buildRight}>
                            <BuildContainer permission={playerPermission} handleAddRow={handleAddRow} removeNewRow={removeNewRow} saveNewRow={saveNewRow} tableRef={tableRef} setShowTable={setShowTable} showTable={showTable} setLoad={setLoad} infoObj={infoObjectToDisplay} listRef={listRef} setInfoObj={(obj) => setInfoObjectToDisplay(obj)} />
                            {!showTable ? <ScrollView horizontal onScroll={handleScroll} ref={scrollRef} nativeID='scroll-flow' style={{ flex: 3, height: "100%", maxHeight: "100%", width: "100%", backgroundColor: background.infoBody, }}>
                                <ScrollSyncPane group="a" >
                                    <View
                                        nativeID='none-scroll-players'
                                        style={{
                                            overflow: "auto",
                                            maxHeight: "100%",
                                            width: "100%",
                                            height: "100%",
                                            borderTopColor: color.border,
                                            borderTopWidth: 1,
                                            backgroundColor: background.infoBody,
                                        }} >
                                        <View style={{ minHeight: "100%", }}>
                                            <View style={{ width: "100%", height: listPlayersRef?.current?.getBoundingClientRect()?.height - widthS * 10, }} >
                                                <Flow
                                                    // heightView={listPlayersRef?.current?.getBoundingClientRect()?.height - widthS * 10}
                                                    topPlayers={topPlayers}
                                                    listPlayersRef={listPlayersRef}
                                                    load={load}
                                                    setLoad={setLoad}
                                                    round={round}
                                                    indexAction={indexAction}
                                                    setIndexAction={setIndexAction}
                                                    indexPlayer={indexPlayer}
                                                    infoObj={infoObjectToDisplay}
                                                    setInfoObj={(obj) => setInfoObj(obj)}
                                                    listRef={listRef} buildRef={buildRef}
                                                    flowTable={flowTable}
                                                    scrollRef={scrollRef}
                                                    permission={playerPermission}
                                                    newScroll={newScroll} />
                                            </View>

                                        </View>
                                    </View>
                                </ScrollSyncPane>
                            </ScrollView>
                                :
                                component
                            }
                        </View>
                    </>
                </ScrollSync>
            </View>
        )
    }

    const analyticsBody = (
        <>
            <View style={[{ borderLeftColor: color.infoBorder, flex: 2, backgroundColor: background.leftInfo }]}>
                <AnalyticsFilter />
            </View>
            <View style={[styles.body]}>
                <AnalyticsIndex />
            </View>
        </>
    )

    const rawDataBody = (
        <>
            <View style={[{ borderLeftColor: color.infoBorder, flex: 2, backgroundColor: background.leftInfo }]}>
                <Filter />
            </View>
            <View style={[styles.body]}>
                <RawData />
            </View>
        </>
    )

    const zoomComponnet = (
        <View
            nativeID='scroll-fixed'
            style={{
                position: "absolute",
                right: 0,
                flexDirection: "row",
                backgroundColor: background.infoHeader,

            }}
        >
            <Pressable
                onPress={() => changeZoom("-")}
                style={{ flex: 1, alignItems: "center", justifyContent: "center", paddingHorizontal: widthS * 7 }}>
                <Entypo name="minus" size={widthS * 7} color="white" />
            </Pressable>
            <Text style={{ color: "white", fontSize: widthS * 7, width: widthS * 20, textAlign: "center" }}>{`${zoomSize}%`}</Text>
            <Pressable
                onPress={() => changeZoom("+")}
                style={{ flex: 1, alignItems: "center", justifyContent: "center", paddingHorizontal: widthS * 7 }}>
                <Image source={{ uri: images.plusIcon }} style={{ width: widthS * 7, height: heightS * 7, resizeMode: "contain" }} />
            </Pressable>
        </View>
    )

    return (
        // <Modal transparent={true} animationType={"none"} visible={flag}>
        <View ref={containerRef} style={[styles.modalC, { display: flag ? "" : "none", zIndex: zIndex }]}>
            <View style={styles.modal}>
                {loadingComponent}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={styles.container}
                    contentContainerStyle={styles.container_m}>
                    <View style={styles.leftNav}>
                        <LeftNav
                            resetChanges={() => { setIsEdit(false); setInfoObjectToDisplay({ ...infoObjectToCompare }) }}
                            checkIfThereIsChanges={() => createKeysInfo(infoObjectToDisplay, infoObjectToCompare)?.length > 0}
                        />
                    </View>
                    {dashboardFlag && <DashboardIndex permission={playerPermission} />}
                    {infoFlag && infoBody}
                    {buildFlag && buildBody()}
                    {analyticsFlag && analyticsBody}
                    {rawDataFlag && rawDataBody}
                </ScrollView>
                <View
                    nativeID='scroll-fixed'
                    style={{
                        width: flowRef.current?.getBoundingClientRect().width,
                        position: "absolute",
                        right: 0,
                        alignItems: "flex-end",
                        backgroundColor: "#29252d"
                    }}
                >
                </View>
                {buildFlag && !showTable &&
                    zoomComponnet}
            </View>
            {realPFlag && <AddPlayer permission={playerPermission} flag={realPFlag} setFlag={() => dispatch(openRealPlayer(false))} isRepresented={false} />}
            {repPFlag && <AddPlayer permission={playerPermission} flag={repPFlag} setFlag={() => dispatch(openRepPlayer(false))} isRepresented={"on"} />}
        </View>
    )
}

const stylesR = (background, color) => {
    return StyleSheet.create({
        modalC: {
            width: "100vw", height: "90%", position: "absolute",
            shadowColor: "black",
            left: 0,
            shadowOpacity: 0.7,
            shadowOffset: { height: 0, width: 0 },
            shadowRadius: 10,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
            height: "100%",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        },
        container: {
            width: "100%",
            height: "100%",
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
        },
        container_m: {
            width: "100%",
            minHeight: "100%",
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
        },
        leftNav: {
            flex: 0.39,
            zIndex: 4
        },
        leftInfo: {
            flex: 1.5,
            borderLeftWidth: 1,
            zIndex: 3
        },
        body: {
            flex: 8,
        },
        buildLeft: {
            borderLeftColor: color.infoBorder,
            backgroundColor: background.leftInfo,
        },
        buildRight: {
            flex: 1,
            backgroundColor: background.infoBody
        }
    })
}

import { FlatList, Image, Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useState, useContext, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';
import { t } from 'i18n-js';
import InjectItem from './InjectItem';
import { useEffect } from 'react';
import CreateAction from '../build/event-components/CreateAction';
import { useRef } from 'react';
import { loading, setErrorMessage } from '../../../../redux/actions';
import onClickEndGameCheck from '../../../Elements/onClickEndGameCheck';
import { editActionCreator, sendActionState } from '../../../../redux/actions/creatorActions';
import { SwimlaneChartContext } from '../../../../ContextData/Swimlanechart';
import AnimationLoading from '../../../modules/AnimationLoading';
import { vwToPx } from '../../../modules/ConvertPXToVw';
import { useCalendars } from 'expo-localization';

export default function Injects({ permission }) {

    const dispatch = useDispatch();

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const lang = useSelector((state) => state.setting.setting.lang);
    const realPlayers = useSelector(state => state.players.realPlayers);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const { sendAction, recallFlowObject } = useContext(SwimlaneChartContext);

    const editAction = useSelector((state) => state.swimlane.editAction);
    const eventsState = useSelector((state) => state.events.events);

    const isPossibletoClose = useRef(null);
    const eventContentRef = useRef(null);

    const { checkEnd } = onClickEndGameCheck();

    const actionsArrState = useSelector((state) => state.swimlane.actions);

    const flatListRef = useRef(null);

    const [flowObjects, setFlowObjects] = useState([]);
    const [nextIndex, setNextIndex] = useState(-1);  // כאן נשמור את אינדקס הפריט הבא לשליחה
    const [showEvent, setShowEvent] = useState(0);
    const [active, setActive] = useState(false);
    const [loadingFlag, setLoading] = useState(true);

    const [localUserAction, setLocalUserAction] = useState(true);

    const hideAction = (id) => {
        // let temp = [...actionsArrState];
        // let action = actionsArrState.findIndex((e) => +e.id === +id);
        // temp[action].show = !temp[action].show;
        // filterActions(temp);
    }

    const isReal = (id, players) => {
        return players?.findIndex(p => p.id === id) != -1;
    }

    const getNextActionIndex = useCallback((flowObjects, index, actionType) => {
        const isEligible = (action) => !action.is_done && !isReal(action?.from_id, realPlayers);

        // אם לא הועבר אינדקס - מצב התחלתי
        if (typeof index !== 'number') {
            let lastDoneIndex = -1;

            // מציאת האינדקס האחרון שנשלח
            for (let i = flowObjects.length - 1; i >= 0; i--) {
                if (flowObjects[i].is_done) {
                    lastDoneIndex = i;
                    break;
                }
            }

            // חיפוש הפעולה הבאה שאינה נשלחת ואינה ממקור אמיתי
            for (let j = lastDoneIndex + 1; j < flowObjects.length; j++) {
                if (isEligible(flowObjects[j])) {
                    return j;
                }
            }

            return flowObjects.length - 1;
        }

        if (actionType === 'send') {
            let lastEligibleIndex = -1;

            // חיפוש מהסוף להתחלה
            for (let i = flowObjects.length - 1; i >= 0; i--) {
                if (isEligible(flowObjects[i])) {
                    lastEligibleIndex = i;
                } else if (flowObjects[i].is_done) {
                    // אם נתקלנו בפעולה שנשלחה, נחזיר את האחרונה שמצאנו
                    if (lastEligibleIndex !== -1) {
                        return lastEligibleIndex;
                    }
                }
            }

            // אם לא נמצאה פעולה מתאימה, נחזיר את האינדקס האחרון
            return lastEligibleIndex !== -1 ? lastEligibleIndex : flowObjects.length - 1;
        }

        if (actionType === 'recall') {
            let potentialIndex = -1;
            const recalledActionIndex = index; // שמירת האינדקס של הפעולה שבוצע לה recall

            // חיפוש אחורה מהאינדקס הנוכחי
            for (let i = flowObjects.length - 1; i >= 0; i--) {
                if (isEligible(flowObjects[i])) {
                    potentialIndex = i;
                } else if (flowObjects[i].is_done && potentialIndex !== -1) {
                    // אם האינדקס הפוטנציאלי גדול מהאינדקס של הפעולה שבוצע לה recall
                    // נחזיר את האינדקס של הפעולה שבוצע לה recall
                    if (potentialIndex > recalledActionIndex) {
                        return recalledActionIndex;
                    }
                    return potentialIndex;
                }
            }

            // אם לא נמצא אינדקס מתאים, נחפש מהתחלה
            for (let j = flowObjects.length - 1; j >= 0; j--) {
                if (isEligible(flowObjects[j])) {
                    // גם כאן נבדוק אם האינדקס גדול מהאינדקס של הפעולה שבוצע לה recall
                    if (j > recalledActionIndex) {
                        return recalledActionIndex;
                    }
                    return j;
                }
            }
            return flowObjects.length - 1;
        }
        // אם לא סופק סוג פעולה, נחזיר את האינדקס האחרון
        return flowObjects.length - 1;
    }, [flowObjects, realPlayers]);


    const filterActions = (arrS) => {
        if (arrS?.length === 0) {
            return setLoading(false);  // עדכון סטטוס טעינה
        } else {
            setLoading(false);
        }
        return [...arrS].sort((a, b) => a.time - b.time) || [];  // סידור לפי time בסדר עולה
    }

    const recallAction = (action, index) => {
        dispatch(
            setErrorMessage(
                true,
                t("oops"),
                t("war_recall_action"),
                async (e) => {
                    if (e) {
                        dispatch(loading(true));
                        recallFlowObject(action).then(async () => {
                            dispatch(loading(false));
                            // עדכון nextIndex לאחר recall
                            // עדכון nextIndex לפריט הבא לשליחה
                            const updatedNextIndex = getNextActionIndex(filterActions(actionsArrState), index, 'recall');
                            await setNextIndex(updatedNextIndex);
                            setLocalUserAction(true);
                        }).catch(() => {
                            dispatch(loading(false));
                        });
                    }
                }))
    }

    const sendInject = (action, index) => {
        console.log('Before send - Current index:', index);
        dispatch(loading(true));
        sendAction(action).then(async (res) => {
            if (res) {
                await dispatch(sendActionState(action?.id));

                // Log the state of flowObjects before calculating next index
                console.log('Flow objects state:', flowObjects);

                const updatedNextIndex = getNextActionIndex(filterActions(actionsArrState), index, 'send');
                console.log('Calculated next index:', updatedNextIndex);

                setNextIndex(updatedNextIndex);
                setLocalUserAction(true);
                dispatch(loading(false));
            }
        }).catch((err) => {
            dispatch(loading(false));
            dispatch(
                setErrorMessage(
                    true,
                    t("oops"),
                    t("alert_mail_error")
                )
            );
        });
    };

    const handleLayout = useCallback((event, index) => {
        const { layout } = event.nativeEvent;
        // layout.height יכיל את גובה הפריט, ו-layout.y את המיקום שלו בגלילה.

        // אם הפריט הגיע לשטח הגלילה, אפשר לבצע פעולות נוספות
        if (layout.y <= 0 && index === nextIndex) {
            console.log(`Item ${index} layout: `, layout);
            console.log(`Item ${index} is visible`);
            setLoading(false);  // עדכון סטטוס טעינה
        }
    }, [nextIndex]);

    useEffect(() => {
        if (Array.isArray(actionsArrState)) {
            setFlowObjects(filterActions(actionsArrState));
        } else {
            setLoading(false);  // עדכון סטטוס טעינה
        }
    }, [actionsArrState]);

    useEffect(() => {
        if (flowObjects?.length > 0) {
            // עדכון nextIndex לפריט הבא לשליחה
            const updatedNextIndex = getNextActionIndex(flowObjects);
            setNextIndex(updatedNextIndex);
        }
    }, [flowObjects]);

    useEffect(() => {
        if (localUserAction && flatListRef.current && nextIndex >= 0 && flowObjects.length > 0) {
            // הגדלת הדיליי לאפשר לכל העדכונים להסתיים
            const timeoutId = setTimeout(() => {
                console.log("Attempting to scroll to index:", nextIndex);
                console.log("Current flowObjects length:", flowObjects.length);

                // ניסיון לגלול מספר פעמים במקרה שהניסיון הראשון נכשל
                const attemptScroll = (attempts = 0) => {
                    if (attempts >= 3) return; // מקסימום 3 ניסיונות

                    try {
                        flatListRef.current?.scrollToIndex({
                            index: nextIndex,
                            animated: true,
                            viewPosition: 0,
                            viewOffset: 0,
                        });
                    } catch (error) {
                        console.log("Scroll attempt failed:", error);
                        setTimeout(() => attemptScroll(attempts + 1), 100);
                    }
                };

                attemptScroll();
                setLocalUserAction(false);
            }, 800); // הגדלת הדיליי מ-400 ל-800

            return () => clearTimeout(timeoutId);
        }
    }, [localUserAction, nextIndex, flowObjects]);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={styles.headerChild}>
                    {/* כפתור הגלילה הבא */}
                    <TouchableOpacity
                        style={styles.addBtn}
                        onPress={() => {
                            if (flatListRef.current && nextIndex !== null && flowObjects.length > 0) {
                                flatListRef.current.scrollToIndex({
                                    index: nextIndex,
                                    animated: true,
                                    viewPosition: 0,
                                    viewOffset: 0
                                });
                            }
                        }}
                    >
                        <Ionicons
                            name="arrow-down"
                            style={styles.iconSend}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.sendBtn} disabled={true}>
                        <Ionicons
                            name="send-outline"
                            style={styles.iconSend}
                        />
                    </TouchableOpacity>
                    <Text style={styles.headerText}>{t("actions").toUpperCase()}</Text>
                </View>
                {permission && <TouchableOpacity style={styles.addBtn} onPress={() => {
                    checkEnd(() => {
                        setShowEvent(3);
                        setActive(false);
                        dispatch(
                            editActionCreator({
                                // x: widthS * 65,
                                // y: heightS * 72,
                                message_id: Math.random(),
                            })
                        );
                    })
                }}>
                    <Image source={{ uri: images.plusIcon }} style={styles.icon} />
                </TouchableOpacity>}
            </View>
            <View style={styles.main}>
                <FlatList
                    nativeID="mail-players"
                    style={styles.scroll}
                    ref={flatListRef}
                    initialNumToRender={30}
                    maxToRenderPerBatch={10}
                    windowSize={10}
                    removeClippedSubviews={false}
                    maintainVisibleContentPosition={{ // הוספת prop זה
                        minIndexForVisible: 0,
                        autoscrollToTopThreshold: 10
                    }}
                    contentContainerStyle={styles.scrollBody}
                    // בדיקה מחודשת להצגת קו ההפרדה רק אם יש שילוב של שני סוגי injects
                    data={Array.isArray(flowObjects) ? flowObjects : []}
                    keyExtractor={(item, index) => index.toString()}
                    // ListEmptyComponent יופיע רק אם אין כלל injects ויש טעינה פעילה
                    // ListEmptyComponent={loadingFlag ? <AnimationLoading flag={true} widget={true} /> : null}
                    renderItem={({ item, index, separators }) => {
                        return (
                            <InjectItem
                                permission={permission}
                                isReal={isReal(item?.from_id, realPlayers)}
                                objectData={item}
                                index={index}
                                hideAction={(id) => hideAction(id)}
                                setShowEvent={setShowEvent}
                                sendInject={(action) => sendInject(action, index - 1)}
                                recall={(action) => recallAction(action, index)}
                                onLayout={(event) => handleLayout(event, index)} // הוספת onLayout לכל פריט
                            />
                        );
                    }}
                    getItemLayout={(data, index) => ({
                        length: vwToPx(5.8), // גובה פריט קבוע, שנה לפי הצורך
                        offset: vwToPx(5.8) * index, // חישוב המרחק של כל פריט
                        index,
                    })}
                    onScrollToIndexFailed={(error) => {
                        // טיפול בשגיאה אם גלילה לא הצליחה
                        console.log("Scroll failed: ", error);
                    }}
                    onEndReachedThreshold={0.5}
                />
                {loadingFlag ? <AnimationLoading flag={true} widget={true} /> : null}
            </View>
            <Modal visible={showEvent === 3 || (isNaN(showEvent) && showEvent !== 0) || editAction} transparent={true} animationType="fade">
                <View style={styles.popupContainer}>
                    <CreateAction
                        isPossibletoClose={isPossibletoClose}
                        setLoad={(flag) => dispatch(loading(flag))}
                        key={editAction}
                        show={showEvent}
                        // active={active}
                        setShow={setShowEvent}
                        events={eventsState}
                        eventContentRef={eventContentRef}
                        setActive={setActive}
                        dashboard={true}
                    />
                </View>
            </Modal>
        </View >
    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            maxHeight: "36vw",
            writingDirection: direction,
        },
        main: {
            backgroundColor: background.infoBox,
            flex: 1,
            borderRadius: "0.8vw",
        },
        scroll: {
            marginVertical: "1vw"
        },
        scrollBody: {
            minHeight: "101%"
        },
        borderBottom: {
            backgroundColor: color.border,
            height: "0.1vw",
            alignSelf: "flex-end",
            width: "97%",
            marginVertical: "0.5vw"
        },
        header: {
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: "0.3vw"
        },
        headerChild: {
            flexDirection: "row",
            alignItems: "center",
            gap: "0.5vw"
        },
        sendBtn: {
            backgroundColor: background.infoBox,
            borderRadius: "100%",
            width: "1.2vw",
            height: "1.2vw",
            alignItems: "center",
            justifyContent: "center"
        },
        addBtn: {
            backgroundColor: color.logoColor,
            borderRadius: "100%",
            width: "1.2vw",
            height: "1.2vw",
            alignItems: "center",
            justifyContent: "center"
        },
        icon: {
            width: "0.6vw",
            height: "0.6vw",
            resizeMode: "contain"
        },
        iconSend: {
            color: color.text2,
            fontSize: "0.6vw"
        },
        headerText: {
            color: color.text2,
            fontSize: "1vw",
            fontFamily: "Catamaran_600SemiBold",
        },
        popupContainer: {
            width: "100%",
            height: "100vh",
            backgroundColor: "#00000075",
            alignItems: "center",
            justifyContent: "center"
        }
    })
}
import io from 'socket.io-client';
import { PORT, HOST } from '@env';

class SocketManager {
    constructor() {
        if (!SocketManager.instance) {
            SocketManager.instance = this;
            this._connectSocket();
        }
        return SocketManager.instance;
    }

    static resetInstance() {
        SocketManager.instance = null; // איפוס המופע הסטטי
    }

    _connectSocket() {
        if (this.socket) {
            this.socket.removeAllListeners();
            this.socket.disconnect();
        }

        this.socket = io(`https://${HOST}:${PORT}`, {
        // this.socket = io(``, {
            transports: ['websocket', 'polling'],
            secure: true,
            timeout: 10000,
            reconnection: false, // לא נשתמש במנגנון החיבור מחדש הפנימי של Socket.IO
        });
    }

    reconnectSocket() {
        this._connectSocket();
    }

    getSocketInstance() {
        return this.socket;
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}

export { SocketManager };
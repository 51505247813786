import { SET_NEWS, EDIT_OR_NEW, NEWS_FLAG, EDIT_NEWS, ADD_NEWS, MARK_NEWS, FIRST_COME_NEWS } from '../actions/index';

import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import { addOrReplaceItemInArr } from '../function/addOrReplaceItemInArr';
const createTime = () => {
      return new Date()
            .toLocaleTimeString('en-US',
                  { hour12: true, hour: 'numeric', minute: '2-digit' }
            );
}

const replaceBrWithNewline = (str) => {
      return str.replace(/<br\s*\/?>/gi, '\n');
};

export const newsReducer = (state = { newsArr: [], unReadNews: 0, unReadNewsArr: [], type: "", post: {}, flag: false, firstComeIn: false }, action) => {
      switch (action.type) {
            case SET_NEWS: {
                  let news = [...state.newsArr];
                  let newsUnread = [...state.unReadNewsArr];
                  action.payload.forEach((e, i) => {
                        let newNews = {
                              id: e.id,
                              logo: e.breaking_news_logo_image ? e.breaking_news_logo_image : null,
                              time: e.last_udpate_date ? e.last_udpate_date : createTime(),
                              time_and_date: e.time_and_date ? e.time_and_date : null,
                              title: e.breaking_news_title ? checkIfIsEncodeText(e.breaking_news_title, "line") : "",
                              image: e.breaking_news_image ? e.breaking_news_image : null,
                              text: e.breaking_news_body ? replaceBrWithNewline(checkIfIsEncodeText(e.breaking_news_body, "html")) : "",
                              show: true,
                              is_read: sessionStorage.getItem("create_game_permission") === "1" ?
                                    e.is_admin_read && e.is_admin_read === "1" ? true : false : e.is_read && e.is_read === "1" ? true : false,
                              share: ["All Players"],
                              to_list: e.to_list ? e.to_list : null,
                              status: e?.status
                        }
                        // if (news?.length > 0) {
                        const { addFlag, arrCurrent } = addOrReplaceItemInArr(news, newNews);
                        news = arrCurrent;
                        if (addFlag && (newNews.is_read === "0" || !newNews.is_read)) {
                              if (!newsUnread.find((n) => n.id === e.id)) {
                                    newsUnread.push(newNews);
                              }
                        } else if (!addFlag && (newNews.is_read === "1" || newNews.is_read)) {
                              let index = newsUnread.findIndex((unRead) => unRead.id === e.id);
                              if (index >= 0) {
                                    newsUnread.splice(index, 1);
                              }
                        }
                        // } else {
                        //       if (newNews?.status) {
                        //             if (newNews.is_read === "0" || !newNews.is_read) {
                        //                   newsUnread.push(newNews);
                        //             }
                        //             news.push(newNews);
                        //       }
                        // }
                  });
                  news.sort((a, b) => {
                        let date1 = new Date(a.time).getTime();
                        let date2 = new Date(b.time).getTime();
                        if (date1 < date2) {
                              return 1
                        } else {
                              return -1
                        }
                  });
                  newsUnread.sort((a, b) => {
                        let date1 = new Date(a.time).getTime();
                        let date2 = new Date(b.time).getTime();
                        if (date1 < date2) {
                              return 1
                        } else {
                              return -1
                        }
                  });
                  newsUnread.forEach((e, i) => {
                        e.index = i;
                  });
                  let firstComeIn = false;
                  if (!sessionStorage.getItem("tooltip")) {
                        firstComeIn = true;
                  }
                  return { ...state, newsArr: news, unReadNews: newsUnread?.length, unReadNewsArr: [...newsUnread], firstComeIn: firstComeIn };
            }
            case EDIT_OR_NEW: {
                  if (action.typeP === "edit") {
                        return { ...state, typeP: action.typeP, post: action.post };
                  } else {
                        return { ...state, typeP: action.typeP, post: {} };
                  }
            }
            case NEWS_FLAG:
                  return { ...state, flag: action.flag };
            case EDIT_NEWS: {
                  let newNews = {
                        id: action.payload.id,
                        logo: action.payload.logo,
                        time: createTime(),
                        title: checkIfIsEncodeText(action.payload.title, "line"),
                        image: action.payload.image,
                        text: checkIfIsEncodeText(action.payload.text, "string"),
                        show: true,
                        is_read: action.read,
                        share: ["All Players"],
                        index: action.payload.index
                  }
                  let temp = [...state.newsArr];
                  let unRead = [...state.unReadNewsArr];
                  temp.forEach((e, i) => {
                        if (e.id === action.payload.id) {
                              temp[i] = newNews;
                        }
                  });
                  unRead.forEach((e, i) => {
                        if (e.id === action.payload.id) {
                              unRead[i] = newNews;
                        }
                  });
                  return { ...state, newsArr: [...temp], unReadNewsArr: [...unRead] };
            }
            case ADD_NEWS: {
                  let newNews = {
                        id: `m${Math.floor(Math.random() * 1000000)}`,
                        logo: action.payload.logo.uri,
                        time: createTime(),
                        title: removeSpacialCherectersString(action.payload.title),
                        image: action.payload.image.uri,
                        text: removeSpacialCherectersString(action.payload.text),
                        show: true,
                        is_read: action.read,
                        share: ["All Players"],
                        index: action.payload.index
                  }

                  let temp = [...state.newsArr];
                  let temp1 = [...state.unReadNewsArr];
                  temp1.unshift(newNews);
                  temp.unshift(newNews);
                  return { ...state, newsArr: [...temp], unReadNewsArr: [...temp1], unReadNews: state.unReadNews + 1 };
            }
            case MARK_NEWS: {
                  let index = state.newsArr.findIndex((e) => e.id === action?.payload);
                  if (index >= 0) {
                        state.newsArr[index].is_read = true;
                        state.unReadNewsArr = state.unReadNewsArr.filter((e) => e.id !== action.payload);
                        state.unReadNews = state.unReadNewsArr.length;
                  }
                  return { ...state, newsArr: [...state.newsArr], unReadNewsArr: [...state.unReadNewsArr], unReadNews: state.unReadNews };
            }
            case FIRST_COME_NEWS: {
                  return { ...state, firstComeIn: false };
            }
            default: {
                  return state;
            }
      }
}
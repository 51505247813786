import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  TextInput,
  Modal,
  Dimensions,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { DocsContext } from "../../../ContextData/Docs";
import { t } from "i18n-js";
import { listenerAddWith } from "../../../AddListener";
import { useSelector, useDispatch } from "react-redux";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import LeftMenuContainerPopups from "../../modules/LeftMenuContainerPopups";
import { loading, setErrorMessage } from "../../../redux/actions";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import { AllowFilesUploaded } from "../../Elements/AllowFilesUploaded";
import { convertByte } from "../../modules/ConvertByte";
import FileLimitPopup from "../../Elements/FileLimitPopup";
import { MAX_FILES_SIZE } from "@env";

export default function AddNewFile({ flag, setFlag, refC }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const popupRef = useRef(null);
  const refContainer = useRef(null);
  const fileRef = useRef(null);

  const dispatch = useDispatch();
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const showErrorMessage = useSelector((state) => state.errorMessage[0]?.flag);

  const [nameFile, setNameFile] = useState("");
  const [des_file, setDes_file] = useState("");
  const [file, setFile] = useState();

  const [error, setError] = useState("");
  const [showFileLimit, setShowFileLimit] = useState(false);

  const { addFile } = useContext(DocsContext);

  const openFilesBrowser = () => {
    DocumentPicker.getDocumentAsync({
      type: AllowFilesUploaded.join(","),
    }).then((res) => res.assets[0]).then((res) => {
      let size = convertByte(res.size);
      if (!AllowFilesUploaded.find((e) => res.mimeType.indexOf(e) >= 0)) {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_files")}`,
          )
        );
        return;
      } else if (size >= MAX_FILES_SIZE) {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_fileSize_mail")}`,
          )
        );
        return;
      } else {
        setFile(res);
      }
    });
  };

  const addFileTo = () => {
    if (nameFile.length <= 1) {
      setError("name");
      return;
    } else if (nameFile.length > 30) {
      dispatch(
        setErrorMessage(
          true,
          undefined,
          `${t("err_fileName_maxLength")}`,
        )
      );
      return;
    } else {
      setError("");
    }
    if (des_file.length <= 2) {
      setError("des");
      return;
    } else {
      if (!file) {
        setError("file");
        return;
      } else {
        setError("");
      }
      setError("");
    }
    setError("");
    dispatch(loading(true));
    addFile(file, `${nameFile}${file.name.slice(file.name.lastIndexOf("."))}`, des_file)
      .then(() => dispatch(loading(false)))
      .catch(() => dispatch(loading(false)));
    setFlag();
  };

  useEffect(() => {
    refContainer?.current?.addEventListener(
      "mousedown",
      listenerAddWith(popupRef, flag, showErrorMessage, setFlag)
    );
    return () => {
      refContainer?.current?.removeEventListener(
        "mousedown",
        listenerAddWith(popupRef, flag, setErrorMessage, setFlag)
      );
    };
  }, [flag]);
  return (
    <Modal transparent={true} visible={flag} animationType="none">
      <View ref={refContainer} style={styles.container}>
        <LeftMenuContainerPopups refC={refC}>
          <View
            style={[styles.main, { backgroundColor: background.popup1 }]}
            ref={popupRef}
          >
            <View style={styles.top}>
              <View style={styles.header}>
                <Text
                  selectable={false}
                  style={[styles.logo_text, { color: color.filesText }]}
                >
                  {t("add-new-file")}
                </Text>
                <HeaderPopupButtons
                  type={true}
                  close={() => {
                    setFlag();
                  }}
                />
              </View>
              <View style={styles.view_name}>
                <Text
                  selectable={false}
                  style={[styles.label, { color: color.filesText }]}
                >
                  {t("doc-name")}:{" "}
                </Text>
                <TextInput
                  style={[
                    styles.inp_name,
                    {
                      borderColor: background.filesInp,
                      backgroundColor: background.filesInp,
                    },
                    error !== "" && !nameFile.length > 0 && styles.mail_error,
                  ]}
                  onChangeText={(txt) => setNameFile(txt)}
                  value={nameFile}
                />
              </View>
              <View style={styles.view_des}>
                <Text
                  selectable={false}
                  style={[styles.label, { color: color.filesText }]}
                >
                  {t("description")}:{" "}
                </Text>
                <TextInput
                  multiline={true}
                  numberOfLines={5}
                  style={[
                    styles.inp_des,
                    {
                      borderColor: background.filesInp,
                      backgroundColor: background.filesInp,
                    },
                    error !== "" && !des_file.length > 0 && styles.mail_error,
                  ]}
                  maxLength={50}
                  onChangeText={(txt) => setDes_file(txt)}
                  value={des_file}
                />
              </View>
              <View style={styles.view_add}>
                <Text
                  selectable={false}
                  style={[styles.label, { color: color.filesText }]}
                >
                  {t("add-file")}:{" "}
                </Text>
                <View style={styles.view_add_child}>
                  <Pressable
                    ref={fileRef}
                    style={[
                      styles.btn_browser,
                      {
                        borderColor:
                          error === "file" ? "red" : background.filesLogo,
                        backgroundColor: background.filesLogo,
                      },
                      error !== "" && !file && styles.mail_error,
                    ]}
                    onPress={openFilesBrowser}
                    onHoverIn={() => { setShowFileLimit(true) }}
                    onHoverOut={() => { setShowFileLimit(false) }}
                  >
                    <Text selectable={false} style={styles.text_btn_browser}>
                      {t("browse")}
                    </Text>
                    {showFileLimit && (
                      <FileLimitPopup
                        refObj={fileRef}
                      />
                    )}
                  </Pressable>
                  <Text
                    selectable={false}
                    style={[
                      styles.label,
                      { marginLeft: 10, color: color.filesText },
                    ]}
                  >
                    {file && file.name}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.bottom, { borderTopColor: color.border }]}>
              <Pressable
                onPress={addFileTo}
                style={[
                  styles.btn_add,
                  { backgroundColor: background.filesLogo },
                ]}
              >
                <Text
                  selectable={false}
                  style={[styles.text_btn, { color: color.filesText }]}
                >
                  {t("upload")}
                </Text>
              </Pressable>
            </View>
          </View>
        </LeftMenuContainerPopups>
      </View>
    </Modal>
  );
}

const stylesR = (width, height, heightS, widthS, direction) =>
  StyleSheet.create({
    container: {
      backgroundColor: "#00000057",
      height: "100%",
      flexDirection: "column",
      fontFamily: "Roboto",
    },
    main: {
      width: widthS * 290,
      height: heightS * 210,
      borderRadius: widthS * 8,
      shadowColor: "black",
      shadowOffset: {
        height: 0,
        width: 0.1,
      },
      shadowOpacity: 1,
      shadowRadius: 6.65,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      position: "absolute",
      top: heightS * 75,
    },
    top: {
      width: "100%",
      paddingLeft: widthS * 20,
      zIndex: 1
    },
    header: {
      justifyContent: "space-between",
      flexDirection: "row",
    },
    logo_text: {
      fontSize: "1.8vw",
      fontFamily: "Catamaran_600SemiBold",
    },
    view_name: {
      display: "flex",
      flexDirection: "row",
      marginTop: heightS * 5,
      alignItems: "center",
      justifyContent: "space-between",
      width: widthS * 240,
      writingDirection: direction,
    },
    label: {
      fontSize: widthS * 8.5,
      fontFamily: "Catamaran_400Regular",
    },
    inp_name: {
      borderWidth: 1,
      width: widthS * 190,
      height: heightS * 18,
      borderRadius: widthS * 7,
      fontSize: widthS * 8.5,
      color: "white",
      paddingHorizontal: widthS * 5,
      outlineStyle: "none",
      paddingVertical: heightS * 2,
      fontFamily: "Catamaran_400Regular",
    },
    inp_des: {
      borderWidth: 1,
      width: widthS * 190,
      height: heightS * 55,
      maxHeight: heightS * 55,
      borderRadius: widthS * 7,
      fontSize: widthS * 8.5,
      fontFamily: "Catamaran_400Regular",
      color: "white",
      paddingHorizontal: widthS * 5,
      paddingVertical: heightS * 2,
      outlineStyle: "none",
    },
    view_add: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: heightS * 10,
      width: widthS * 240,
      writingDirection: direction,
    },
    view_add_child: {
      width: widthS * 190,
    },
    btn_browser: {
      borderWidth: 1,
      paddingHorizontal: widthS * 7,
      paddingVertical: heightS * 2.5,
      borderRadius: widthS * 7,
      marginLeft: widthS * 15,
      width: "fit-content"
    },
    text_btn_browser: {
      color: "white",
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
    },
    view_des: {
      display: "flex",
      flexDirection: "row",
      marginTop: heightS * 10,
      justifyContent: "space-between",
      width: widthS * 240,
      writingDirection: direction,
    },
    // view_share: {
    // 	display: "flex",
    // 	flexDirection: "row",
    // 	marginTop: heightS * 6,
    // },
    // list_share_scroll: {
    // 	maxWidth: widthS * 130,
    // 	maxHeight: heightS * 100,
    // 	margin: 5,
    // 	marginLeft: widthS * 5,
    // 	backgroundColor: "#ffffff63",
    // 	borderWidth: 1,
    // },
    // share_item: {
    // 	display: "flex",
    // 	flexDirection: "row",
    // 	alignItems: "center",
    // 	padding: widthS * 5,
    // 	paddingBottom: 0,
    // },
    // border_circle: {
    // 	borderWidth: 1,
    // 	borderColor: "#314c59",
    // 	width: widthS * 9,
    // 	height: widthS * 9,
    // 	borderRadius: 15,
    // 	marginRight: widthS * 5,
    // 	backgroundColor: "#ffffff63",
    // 	padding: 0,
    // },
    // circle: {
    // 	backgroundColor: "orange",
    // 	width: widthS * 7,
    // 	height: widthS * 7,
    // 	borderRadius: 15,
    // 	margin: widthS * 0.5,
    // },
    // share_item_text: {
    // 	fontSize: widthS * 8,
    // 	color: "white",
    // },
    bottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTopWidth: 1,
      paddingVertical: heightS * 10,
      width: "100%",
      maxHeight: heightS * 50,
    },
    btn_add: {
      width: widthS * 85,
      display: "flex",
      height: heightS * 20,
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: heightS * 2,
      paddingHorizontal: widthS * 15,
      borderRadius: widthS * 7,
    },
    text_btn: {
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
    refContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    x: {
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 14,
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: "10px",
    },
  });
